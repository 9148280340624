<template>
    <div >
        <!-- selModule = {{selModule}} <br>selTask = {{selTask}}<hr>selTask = {{selTask.title}} id = {{selTask.id}} -->
         <div elevation="3" class="eduRow eduHeader px-6 py-4" >
           <!-- selModule = {{selModule}}<hr>
           selTask = {{selTask}} -->
          
            <div v-if="selModule && selTask">
              
                <div class="d-flex align-center ">
                    <v-card elevation="4" color="primary" class="ma-2">
                        <div class="headSubTitles">Module</div> 
                        <div class="headTitles">{{selModule.naam}}</div>
                    </v-card>
                    <v-card elevation="4" color="primary" class="ma-2 ml-4 ">
                        <div class="headSubTitles">Taak </div> 
                        <div class="headTitles">{{selTask.title}}</div>
                    </v-card>
                    
                    <v-card elevation="4" color="primary" class=" ma-2 ml-auto">
                       <!-- <v-btn 
                            icon 
                            @click="nextPage(false)" 
                            class="white--text"
                            :disabled="selModule.taken[0].id == selTask.id"
                        >
                            <v-icon>mdi-arrow-left-bold-box-outline</v-icon>
                        </v-btn>
                        <v-btn  
                            icon 
                            @click="nextPage(true)" 
                            class="white--text"
                            :disabled="selModule.tasks[selModule.taken.length-1].id == selTask.id"
                        >
                            <v-icon>mdi-arrow-right-bold-box-outline</v-icon>
                        </v-btn>-->
                    </v-card> 
                </div>
            </div>
            <div v-else>
                <h3 class="red--text">Geen module geselecteerd</h3>
            </div>

            <div class="eduModules">
<!-- ---------- start --------------- colorgame ------------------------------------------------------------------------- -->
                <div class="px-6">
                    <v-card class="eduRow" v-for="(row, index) in selTask.rijen" v-html="row.html" :key="index" >
                       <span ></span>


                    </v-card>
                </div>
            </div>
             
        </div>

         <!-- <div class="eduModules">  </div>  -->
    </div>
</template>

<script>

export default {
	//name: "eduBase"

    props: {
        selTaskProp: Object,
        selModuleProp: Object,
    },

	data: () => ({
        selTask: {},
        selModule:{},
        countClass:0,
        panel: [],
	}),

    watch: {
		//wanneer this.items veranderd van null naar gevuld, runt hij onderstaande
		selModuleProp: function (selModule) {//, oldItems
			if (selModule) {
                //console.log("newItems = ",newItem);
                this.selModule = selModule; 
                console.log("vanuit watch this.selModule = ",this.selModule);
            }
        },
        selTaskProp: function (selTask) {//, oldItems
			if (selTask) {
                //console.log("newItems = ",newItem);
                this.selTask = selTask;
                console.log("vanuit watch this.selTask = ",this.selTask);
            }
        },
        
    },

    mounted() {
        //console.clear();
        // console.log(" mounted this.selModuleProp = ",this.selModuleProp);
        // console.log(" mounted this.selTaskProp = ",this.selTaskProp);
        this.selModule = this.selModuleProp;
        this.selTask   = this.selTaskProp;
        //console.log(" mounted this.selModule = ",this.selModule);
        // console.log(" mounted this.selTask = ",this.selTask);
    },

    methods: {
        toggleExpansion : function() {
           console.log("clicked this=", this.$refs)
        },

        nextPage: function(right) {
            const index = this.selModule.tasks.findIndex(object => {return object.id === this.selTask.id;});
            //console.log("index = ", index);
            if(right) {
                //var index = this.selModule.tasks.map(function(e) { return e.id; }).indexOf(this.selTask.id); 
                if(index+1 <= this.selModule.tasks.length) { //controle eigenlijk overbodig omdat button dan disabled is
                    this.selTask = this.selModule.tasks[index+1]
                }                
            } else {
                if(index-1 >= 0) { //controle eigenlijk overbodig omdat button dan disabled is
                    this.selTask = this.selModule.tasks[index-1]
                }   
            }
        },

        
    }

};
</script>

<style> 
    @import './edu.css';
    h1, h2, h3, h4 { /* moet specifieker als hij in edu.css staat, daarom hier */
        margin: 0 0 0.3em 0;
    }

    .ul-min {
        margin:0;
        margin-bottom:5px;
    }
</style>
