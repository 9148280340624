<template>
	<div class="viewEdu" :style="[mini ? {'margin':'0 0 0 0px'} : {'margin':'0 0 0 '+(left_drawer_width-55)+'px'}]"  >
        <edu-all-new :selModuleProp=selModule :selTaskProp=selTask></edu-all-new>

        <v-navigation-drawer
            v-model="left_drawer"
            :mini-variant.sync="mini"
            permanent
            absolute
            v-bind:width="left_drawer_width"
        >
            <v-card>
                <v-list-item class="px-2">
                    <v-list-item-avatar @click="toggleMini">
                        <v-icon color="indigo" class="mr-2">mdi-menu</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>
                        Kies module
                    </v-list-item-title>
                    <v-btn icon @click="toggleMini">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="indigo">mdi-script-text-play-outline</v-icon>
                        </v-list-item-icon>
                    
                        <v-list-item-content>
                            <!-- <v-list-item-title class="text-h6"><span>module</span></v-list-item-title> -->
                            <v-list-item-subtitle>
                                <v-select dense :items="modules" item-text="afkorting" label="module" return-object filled 
                                v-model="selModule" @change="changemodule" >
                                </v-select>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <div v-for="(taak, index) in selModule.taken" :key="index" class="d-flex flex-row">
                        <v-list-item v-if="selModule">
                            <v-avatar 
                                size="25" 
                                class="av_a"
                                @click="selTask=taak"
                            > 
                                {{index+1}}
                            </v-avatar>
                               
                            <v-list-item-content>
                                <v-btn @click="selTask=taak">{{taak.title}}</v-btn>
                            </v-list-item-content>
                        
                        </v-list-item>
                    </div>

                </v-list>
                <!-- selTask = {{selTask}} -->
            </v-card>
        </v-navigation-drawer>
	</div>
</template>

<script>
import eduAllNew from "@/components/edu_pages/edu_all_new";
import { getDoelen, getModules, getTakenPerModId, getRijenPerTaakId} from "@/api/edu.js";

export default {
	//name: "",
	components: {
        eduAllNew,
	},

	data: () => ({
        selFile: "",
        selModule: {},
        selTask: {},
        mini: true,
        left_drawer:true,
        left_drawer_width:250,
        modules:[],
	}),

    watch: {
		//wanneer  er nog al "/edu" in de route staat ververst hij niet bij "/edu/csp1" o.i.d., met een watch op de $route wel
		// $route: function (newItems) { //, oldItems
		// 	if (newItems) {
		// 		this.getSelModuleTasks();
		// 	}
		// },
      
		//wanneer this.items veranderd van null naar gevuld, runt hij onderstaande
		// selModuleProp: function (selModule) {//, oldItems
		// 	if (selModule) {
        //         //console.log("newItems = ",newItem);
        //         this.selModule = selModule; 
        //         console.log("vanuit watch this.selModule = ",this.selModule);
        //     }
        // },
        // selTaskProp: function (selTask) {//, oldItems
		// 	if (selTask) {
        //         //console.log("newItems = ",newItem);
        //         this.selTask = selTask;
        //         console.log("vanuit watch this.selTask = ",this.selTask);
        //     }
        // },
    
	},

    created() {
        // voor als er nog geen "/edu" in de route stond
        
        getDoelen().then((apiData) => {
			if (apiData.bSuccess) {
                this.doelen = apiData.data;
                this.$root.$emit("showSnackbar", { text: apiData.sMessage, type: "success", time: 7000 });
                //console.log("in edu.vue, created, getDoelen() this.items:", this.items);
            } else {
                this.$root.$emit("showSnackbar", { text: "3) "+apiData.sMessage, type: "error", time: 7000 });
            }
        });

        getModules().then((apiData) => {
			if (apiData.bSuccess) {
                this.modules = apiData.data;
                this.$root.$emit("showSnackbar", { text: apiData.sMessage, type: "success", time: 7000 });
                //console.log("in edu.vue, created, getModules() this.modules:", this.modules);
                this.selModule = this.modules[5];
                //console.log("in edu_new.vue, created, selModule = [5] this.selModule:", this.selModule);

                this.getSelModuleTasks(); 
            } else {
                this.$root.$emit("showSnackbar", { text: "4) "+apiData.sMessage, type: "error", time: 7000 });
                //console.log("in edu.vue, created, getModules() apiData.sMessage:", apiData.sMessage);
            }
        });

        let b = false;
        if(b) { // SQL opbouw vanuit JSON !!! LATEN STAAN!
            let sql ="";
            /* 
                console.log("this.modules[2].tasks = ",this.modules[2].tasks); 
                    this.modules[2].tasks.forEach((val, index) => {
                    console.log("val = ",val); console.log("index = ",index);
                    sql += "INSERT INTO `taak` (`id`, `idModule`, `volgorde`   , `afkorting`, `title`, `omschrijving`, `criteria`, `bBeo`)";
                    sql += "            VALUES (NULL, '6'       , '"+(index+1)+"', 'F"+(index)+"', '"+val.title+"', '"+"', '', '0');\n";
                });
            */
            console.clear();
            console.log("this.modules[2].tasks[5].leerdoelen = ",this.modules[2].tasks[5].leerdoelen); 
            let aItems = Object.keys(this.modules[2].tasks[5].leerdoelen)
            console.log("Object.keys(this.modules[2].tasks[5].leerdoelen) = ",aItems); 

            sql += " TRUNCATE doel__categorie; \n";
            sql += " TRUNCATE doel; \n";
            aItems.forEach((val, index) => {
                //console.log("val = ",val); console.log("index = ",index);
                sql += "INSERT INTO `doel__categorie` (`id`, `id_cat_parent`, volgorde, `categorie`, `omschrijving`)";
                sql += "                       VALUES (NULL, '0'            , '"+(index+1)+"'  ,'"+val+"'  , ''        );\n";

                sql += " SET @lastID = LAST_INSERT_ID();\n";

                //Object.keys(aItems[val]).forEach((val2, index2) => {
                this.modules[2].tasks[5].leerdoelen[val].forEach((val2, index2) => {
                    console.log("val = ",val); console.log("val2 = ",val2); console.log("index2 = ",index2);
                    sql += "INSERT INTO `doel` (`id`, id_cat , volgorde        , `doel`    , `opmerking`)";
                    sql += "            VALUES (NULL, @lastID, '"+(index2+1)+"','"+val2+"' , ''         );\n";
                    //SELECT LAST_INSERT_ID();
                });
            });
            console.log(sql);
        }
        
	},

    methods: {
        toggleMini: function() {
            this.mini = !this.mini;
            if(this.mini) {
                    //
            }
            //console.log("in toggleMini. this.mini = "+this.mini);
        },

        changemodule: function() {
            console.log("in changemodule. this.selModule = ", this.selModule);
            this.getSelModuleTasks();
            // this.selTask = this.selModule.taken[0];
            // console.log("in changemodule.  this.selTask = this.selModule.taken[0] is geset ", this.selTask);
            //this.mini = false;
        },

        changeTaak: function() {
            this.getSelTaakRijen();
            console.log("in mounted edu_all_new.vue. this.selTask.rijen =", this.selTask.rijen)
        },

        getSelModuleTasks: function() {
            //console.log("newItems", newItems); //console.log("oldItems", oldItems);console.log("this.$route.path =", this.$route.path);
            let b = false;
            if(b) { //route controle
                console.log("this.modules =", this.modules);
                console.log("this.$route.path =", this.$route.path);
                if(this.$route.path == "/edu/pr22") {
                    const index = this.modules.findIndex(object => {return object.id === "pr22";});
                    this.selModule = this.modules[index];
                    //console.log("index =", index);
                }
                if(this.$route.path == "/edu/csp1") {
                    const index = this.modules.findIndex(object => {return object.id === "csp1";});
                    this.selModule = this.modules[index];
                    //console.log("index =", index);
                }
                if(this.$route.path == "/edu/cg") {
                    const index = this.modules.findIndex(object => {return object.id === "cg";});
                    this.selModule = this.modules[index];
                    //console.log("index =", index);
                }
            
                console.log("in edu_new, getSelModuleTasks this.selModule.id="+this.selModule.id)
            }
            
            getTakenPerModId(this.selModule.id).then((apiData) => { 
                if (apiData.bSuccess) {
                    this.selModule.taken = apiData.data;
                    this.selTask = this.selModule.taken[0];
                    this.$root.$emit("showSnackbar", { text: apiData.sMessage, type: "success", time: 7000 });
                    //console.log("in edu_new.vue, created, getModules() this.selTask:", this.selTask);
                    this.getSelTaakRijen();
                } else {
                    this.$root.$emit("showSnackbar", { text: "5) "+apiData.sMessage, type: "error", time: 7000 });
                    //console.log("in edu.vue,  apiData.sMessage:", apiData.sMessage);
                }
            });
            
            // console.log("this.selModule =", this.selModule);
            // console.log("this.selTask =", this.selTask);
        },

        getSelTaakRijen: function() {
            //console.log("in edu_all_new.vue, methods, getSelTaakRijen() this.selTask.id:", this.selTask.id);
            getRijenPerTaakId(this.selTask.id).then((apiData) => { 
                
                if (apiData.bSuccess) {
                    this.selTask.rijen = apiData.data;
                    this.$root.$emit("showSnackbar", { text: apiData.sMessage, type: "success", time: 7000 });
                    //console.log("in edu.vue, methods, getSelTaakRijen() this.selTask.rijen:", this.selTask.rijen);
                } else {
                    this.$root.$emit("showSnackbar", { text: "6) "+apiData.sMessage, type: "error", time: 7000 });
                    //console.log("in edu.vue, created, getModules() apiData.sMessage:", apiData.sMessage);
                }
            });
            
            // console.log("this.selModule =", this.selModule);
            // console.log("this.selTask =", this.selTask);
        },
	},
};
</script>
<style scoped>
    .av_a {
        background-color: darkslategrey;
        color:white!important;
        margin-right:15px;
    }
</style>
